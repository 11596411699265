(function () {
	on(window, 'load', function () {
		// Start
		$(document).foundation();
	});
})();


/*global EaseScroll, lazyload*/
'use strict';
import { on, off, throttle, isInViewport } from './utils/_fn';
import { UAParser } from 'ua-parser-js';

/* ---------------------------------------- [START] Browser Tip */
(function() {
	window.UAParser = UAParser;
	const uap = new UAParser();
	const uapResult = uap.getResult();
	if (uapResult.browser.name.includes('IE') ||
		(uapResult.browser.name.includes('Chrome') && +(uapResult.browser.version.split('.')[0] < 85)) ||
		(uapResult.browser.name.includes('Firefox') && +(uapResult.browser.version.split('.')[0] < 75)) ||
		(uapResult.browser.name === 'Edge' && +(uapResult.browser.version.split('.')[0] < 80)) ||
		(uapResult.browser.name.includes('Safari') && +(uapResult.browser.version.split('.')[0] < 13)) ||
		(uapResult.os.name === 'iOS' && +(uapResult.os.version.split('.')[0] < 13)) ||
		(uapResult.os.name === 'Android' && +(uapResult.os.version.split('.')[0] < 5))
	) {
		// Chrome 手機模擬器 Android 是 6.0 不能擋以上的版本
		location.href = 'browser.html';
	}
})();
(function (window, document) {
    /* ---------------------------------------- [START] Windows Setting */
	const html = document.documentElement;
	const body = document.body;
	let ww = window.innerWidth;
	let wh = window.innerHeight;
	let ws = 0;
	function getScrollTop(target = window) {
		return (target.pageYOffset || html.scrollTop) - (html.clientTop || 0);
	}
	function getWinSet() {
		ww = window.innerWidth;
		wh = window.innerHeight;
		ws = getScrollTop();
	}
	on(window, 'load', getWinSet);
	on(window, 'resize', throttle(getWinSet, 50, 100));


	/* ---------------------------------------- [START] 判斷 Browser + Device (使用 ua-parse-js) */
	let isSmartDevice = false; // 是否是智慧型裝置
	let isMobile = false; // 是否是手機（無法跟平板同時）
	let isTablet = false; // 是否是平板（無法跟手機同時）
	function deviceBrowserCheck() {
		const uap = new UAParser();
		const uapResult = uap.getResult();
		html.classList.add(uapResult.browser.name.toLocaleLowerCase().replace(/\s/g, '-'));
		html.classList.add(uapResult.os.name.toLocaleLowerCase());

		// IsMobile
		isMobile = uapResult.device.type === 'mobile';
		if (isMobile) {
			html.classList.add('is-mobile');
		} else {
			html.classList.remove('is-mobile');
		}

		// IsTable
		isTablet = uapResult.device.type === ('tablet');
		if (isTablet) {
			html.classList.add('is-tablet');
		} else {
			html.classList.remove('is-tablet');
		}

		isSmartDevice = (isMobile || isTablet);
	}

	// 防止測試時一直用開發者工具Resize出現Bug
	on(window, 'resize', throttle(deviceBrowserCheck, 50, 100));
	on(window, 'load', deviceBrowserCheck);

	/* ---------------------------------------- [START] Lazyload */
	/*
	 * 使用：https://github.com/verlok/vanilla-lazyload
	 * 尋找頁面上的 .lazy 為執行 Lazy Load 物件
	 */
	var lazyloadTimer = 0;
	function buildLazyLoad() {
		if (lazyloadTimer < 5 && window.LazyLoad === undefined) {
			return setTimeout(function () {
				lazyloadTimer++;
				buildLazyLoad();
			}, 500);
		}

		var lazyLoadInstance = new LazyLoad({
			// Your custom settings go here
		});
	}
	on(window, 'load', buildLazyLoad);
})(window, document);